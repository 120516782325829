import { z } from "zod";

import { defaultListParamsSchema } from "./default-list-params";

export type DateRange = z.infer<typeof dateRangeSchema>;
export const dateRangeSchema = z.object({
  dateFrom: z.coerce.date().optional(),
  dateTo: z.coerce.date().optional(),
});

export type DefaultListDateRangeParams = z.infer<
  typeof defaultListDateRangeSchema
>;
export const defaultListDateRangeSchema =
  defaultListParamsSchema.merge(dateRangeSchema);

export const getDefaultListDateRangeLoaderDeps = (opts: {
  search: unknown;
}): DefaultListDateRangeParams => {
  return defaultListDateRangeSchema.parse(opts.search);
};
