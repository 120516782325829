import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

import { CreateDepartment, Employee, List, PaymentPlan } from "@elton/types";

import { EmployeesPage } from "@/pages/employees-page/employees-page";

import { defaultListParamsSchema } from "@/api/params/default-list-params";
import { getDepartments } from "@/api/requests/departments/get-departments";
import { getEmployees } from "@/api/requests/employees/get-employees";
import { getCompanyPaymentPlanOverride } from "@/api/requests/payment-plan/get-payment-plan-override";

const SearchParamsSchema = defaultListParamsSchema.extend({
  open: z.string().optional(),
});

type EmployeesSearchParams = z.infer<typeof SearchParamsSchema>;

const Route = createFileRoute(
  "/_authenticated/companies/$companyId/_dashboard/employees",
)({
  component: EmployeesPage,
  validateSearch: SearchParamsSchema,
  loaderDeps: ({ search: { offset, limit, filter, sort, open } }) => ({
    offset,
    limit,
    filter,
    sort,
    open,
  }),
  loader: async ({
    params,
    deps,
  }): Promise<{
    employees: List<Employee>;
    departments: List<CreateDepartment>;
    companyId: string;
    companyPlan: PaymentPlan | null;
    hasReachedUserLimit: boolean;
  }> => {
    const [departments, employees, companyPlan] = await Promise.all([
      getDepartments(params.companyId),
      getEmployees(params.companyId, deps),
      getCompanyPaymentPlanOverride(params.companyId),
    ]);

    return {
      companyId: params.companyId,
      departments,
      employees,
      companyPlan,
      hasReachedUserLimit: hasReachedUserLimit(companyPlan, employees.total),
    };
  },
});

export { Route };
export type { EmployeesSearchParams };

const hasReachedUserLimit = (
  companyPlan: PaymentPlan | null,
  employeesTotal: number,
) => {
  return companyPlan ? companyPlan.userLimit <= employeesTotal : false;
};
