import { useMemo } from "react";
import { z } from "zod";

import { DateRange } from "@/api/params/default-list-date-range-params.ts";

export const useDateRange = (params: DateRange) => {
  return useMemo(() => {
    const dateSchema = z.coerce.date().optional();

    const dateFromRes = dateSchema.safeParse(params.dateFrom);
    const dateToRes = dateSchema.safeParse(params.dateTo);
    return {
      range: {
        from: dateFromRes.data,
        to: dateToRes.data,
      },
    };
  }, [params.dateFrom, params.dateTo]);
};
