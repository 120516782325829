import { Company } from "@elton/types";
import { defaultCreateCompany } from "@elton/types/mocks";

import { CreateCompanyForm } from "@/components/forms/company-form/default/create-company-form";
import { CompanyTable } from "@/components/tables/company-table/company-table.tsx";

import { useCreateCompanyMutation } from "@/api/mutations/company/create-company-mutation";
import { useDeleteCompanyMutation } from "@/api/mutations/company/delete-company-mutation";
import { DefaultListParams } from "@/api/params/default-list-params";
import { Route as CompaniesRoute } from "@/routes/_authenticated/companies";
import { useT } from "@transifex/react";
import { useRole } from "@/context/role-context/use-role.tsx";
import { BlankLayout } from "@/pages/_layouts/blank-layout/blank-layout.tsx";

export const CompaniesPage: React.FC = () => {
  const t = useT();
  const { isAdmin } = useRole();

  const loaderData = CompaniesRoute.useLoaderData();
  const searchParams = CompaniesRoute.useSearch<DefaultListParams>();

  const createCompany = useCreateCompanyMutation();
  const deleteCompany = useDeleteCompanyMutation();

  return (
    <BlankLayout>
      <CompanyTable<Company>
        title={t("Companies")}
        description={t(
          "Manage all your companies - Click a company to inspect or make changes.",
        )}
        buttonTitle={t("Create company")}
        showButton={isAdmin}
        fullPath={CompaniesRoute.fullPath}
        companies={loaderData.companies}
        createCompany={createCompany}
        deleteCompany={deleteCompany}
        searchParams={searchParams}
        defaultCreateCompany={defaultCreateCompany}
        CreateCompanySheet={({
          createCompany,
          formData,
          handleCreateCompany,
        }) => (
          <CreateCompanyForm
            loading={createCompany.isPending}
            data={formData}
            paymentPlans={loaderData.paymentPlans.data}
            onCreate={handleCreateCompany}
            onUpdate={() => {}}
          />
        )}
      />
    </BlankLayout>
  );
};
