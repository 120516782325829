import { createFileRoute } from "@tanstack/react-router";

import { ExportsPage } from "@/pages/exports-page/exports-page";

import { getDefaultListDateRangeLoaderDeps } from "@/api/params/default-list-date-range-params.ts";

export const Route = createFileRoute("/_authenticated/_restricted/exports")({
  component: ExportsPage,
  loaderDeps: getDefaultListDateRangeLoaderDeps,
});
