import React, { useState } from "react";
import { UseMutationResult } from "@tanstack/react-query";

import {
  ChildCompany,
  Company,
  CreateChildCompany,
  CreateCompany,
  List,
  ListCompany,
} from "@elton/types";

import { AlertDialog } from "@ui/components/ui/alert-dialog.tsx";
import { SearchInput } from "@ui/components/ui/input.tsx";
import { Sheet, SheetContent } from "@ui/components/ui/sheet.tsx";
import { useToast } from "@ui/components/ui/use-toast.tsx";

import { useCompanyColumns } from "@/components/tables/columns/company-columns.tsx";
import { DynamicTable } from "@/components/tables/dynamic-table/dynamic-table.tsx";

import { DefaultDialogContent } from "@/pages/_common/default-alert-content.tsx";
import { DefaultTopSectionContent } from "@/pages/_common/default-top-section-content.tsx";

import { useDebouncedSearchNavigation } from "@/hooks/search/use-search-navigation.tsx";

import { DefaultListParams } from "@/api/params/default-list-params.ts";
import { useT } from "@transifex/react";

type CompanyType = Company | ChildCompany;
type TCreateCompany<T extends CompanyType> = T extends Company
  ? CreateCompany
  : T extends ChildCompany
    ? CreateChildCompany
    : never;

type CreateCompanySheetProps<TCompany extends CompanyType> = {
  createCompany: UseMutationResult<TCompany, Error, TCreateCompany<TCompany>>;
  formData: TCreateCompany<TCompany>;
  handleCreateCompany: (data: TCreateCompany<TCompany>) => void;
};

interface CompanyTableProps<TCompany extends CompanyType> {
  title: string;
  description: string;
  buttonTitle: string;
  showButton?: boolean;
  fullPath: string;
  companies: List<ListCompany>;
  createCompany: UseMutationResult<TCompany, Error, TCreateCompany<TCompany>>;
  deleteCompany: UseMutationResult<TCompany, Error, string>;
  searchParams: DefaultListParams;
  defaultCreateCompany: TCreateCompany<TCompany>;
  CreateCompanySheet: (
    props: CreateCompanySheetProps<TCompany>,
  ) => React.ReactNode;
}

export const CompanyTable = <TCompany extends CompanyType>({
  title,
  description,
  buttonTitle,
  showButton = true,
  fullPath,
  companies,
  createCompany,
  deleteCompany,
  searchParams,
  CreateCompanySheet,
  defaultCreateCompany,
}: CompanyTableProps<TCompany>) => {
  const t = useT();
  const { toast } = useToast();

  const [formOpen, setFormOpen] = useState(false);
  const [formData, setFormData] =
    useState<TCreateCompany<TCompany>>(defaultCreateCompany);
  const [selected, setSelected] = useState<ListCompany | null>(null);

  const columns = useCompanyColumns({
    onDelete: (companyId: string) => {
      const found = companies?.data?.find(
        (company) => company.id === companyId,
      );
      if (found) {
        setSelected(found);
      }
    },
    onCopyId: (companyId: string) => {
      navigator.clipboard.writeText(companyId);
      toast({
        variant: "info",
        description: t("ID copied to the clipboard."),
      });
    },
  });

  const { updateSearchFilter } = useDebouncedSearchNavigation(
    fullPath,
    searchParams,
  );

  const handleProceedWithDeletion = () => {
    if (selected) {
      deleteCompany.mutate(selected.id!);
    }
  };

  const handleCreateCompany = async (data: TCreateCompany<TCompany>) => {
    await createCompany.mutateAsync(data);
    setFormOpen(false);
  };

  return (
    <AlertDialog>
      <Sheet open={formOpen} onOpenChange={setFormOpen}>
        <div className="w-full">
          <DefaultTopSectionContent
            title={title}
            description={description}
            buttonTitle={buttonTitle}
            showButton={showButton}
            onClick={() => setFormData(defaultCreateCompany)}
          />
          <div className="relative w-full">
            <DynamicTable
              renderToolbar={() => {
                return (
                  <>
                    <SearchInput
                      defaultValue={searchParams.filter}
                      containerClassName="w-full sm:max-w-96"
                      placeholder={t("Search") + ".."}
                      onChange={updateSearchFilter}
                    />
                  </>
                );
              }}
              columns={columns}
              data={companies.data}
              total={companies.total}
            />
          </div>
        </div>
        <SheetContent className="space-y-4">
          <CreateCompanySheet
            createCompany={createCompany}
            formData={formData}
            handleCreateCompany={handleCreateCompany}
          />
        </SheetContent>
      </Sheet>
      <DefaultDialogContent
        title={t("Do you want to proceed?")}
        description={t(
          "This action cannot be undone. This will permanently delete {name} from your organization.",
          { name: selected?.name },
        )}
        onContinue={handleProceedWithDeletion}
      />
    </AlertDialog>
  );
};
