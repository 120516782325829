import { useCallback } from "react";
import { useNavigate, useSearch } from "@tanstack/react-router";
import { endOfDay, startOfDay } from "date-fns";
import { SessionsParams } from "@/routes/_authenticated/companies/$companyId/_dashboard/sessions.tsx";
import { DateRange } from "@/api/params/default-list-date-range-params.ts";
import { useDebounce } from "@/hooks/debounce/useDebounce.tsx";

const DEFAULT_LIMIT = 10;
const FILTER_DEBOUNCE_MS = 500;
const DATE_DEBOUNCE_MS = 0;
const INCLUDE_CHILDREN_DEBOUNCE_MS = 0;

export const useSessionSearchParamsUpdater = (initialPath: string) => {
  const previousSearch = useSearch({
    from: "/_authenticated/companies/$companyId/_dashboard/sessions",
  });

  const navigate = useNavigate({ from: initialPath });

  const updateSearchParams = useCallback(
    (updates: Partial<SessionsParams>) => {
      const nextSearch = {
        ...previousSearch,
        ...updates,
      };

      navigate({
        search: nextSearch as any,
        replace: true, // Add replace to prevent building up history
      });
    },
    [navigate, previousSearch],
  );

  const debouncedUpdateFilter = useDebounce((filterValue: string) => {
    const updates: Partial<SessionsParams> = {
      filter: filterValue,
      offset: 0, // Always reset pagination on filter change
      limit: DEFAULT_LIMIT,
    };

    updateSearchParams(updates);
  }, FILTER_DEBOUNCE_MS);

  const debouncedUpdateDateRange = useDebounce(
    (dateRange: Partial<DateRange>) => {
      const updates: Partial<SessionsParams> = {
        dateFrom: dateRange.dateFrom
          ? startOfDay(new Date(dateRange.dateFrom))
          : undefined,
        dateTo: dateRange.dateTo
          ? endOfDay(new Date(dateRange.dateTo))
          : undefined,
        offset: 0, // Always reset pagination on date change
        limit: DEFAULT_LIMIT,
      };

      updateSearchParams(updates);
    },
    DATE_DEBOUNCE_MS,
  );

  const debouncedUpdateIncludeChildren = useDebounce(
    (includeChildren: boolean) => {
      const updates: Partial<SessionsParams> = {
        includeChildren,
        offset: 0,
        limit: DEFAULT_LIMIT,
      };

      updateSearchParams(updates);
    },
    INCLUDE_CHILDREN_DEBOUNCE_MS,
  );

  return {
    updateSearchFilter: debouncedUpdateFilter,
    updateDateRange: debouncedUpdateDateRange,
    updateIncludeChildren: debouncedUpdateIncludeChildren,
  };
};
